:root {
  --shadow-1: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  --shadow-2: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  --shadow-3: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  --shadow-4: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  --shadow-5: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  --bg-main: rgb(37, 131, 219);
  --bg-main-dark: rgb(16, 92, 192);
  --bg-darker:	rgb(34, 34, 34);
  --bg-dark:	rgb(92, 92, 92);
  --bg-light:	rgb(177, 177, 177);
  --bg-blue-muted: #3a08c2;
}

* {
  margin: 0;
  font-family: 'Raleway', sans-serif;
}

*:focus {
  outline: none !important;
}

*::-webkit-scrollbar-track {
    background-color: #F5F5F5;
}

*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--bg-main-dark);
}

body {
  overflow-x: hidden;
}

button {
  border: none;
}

.main-font {
  font-family: 'Montserrat', sans-serif;
}

.button-font {
  font-family: 'Raleway', sans-serif;
}

.ReactModalPortal {
  z-index: 4;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.shadow-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.shadow-2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.shadow-3 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.shadow-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.shadow-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.centered-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}