$bar-color: #fff;
$ball-color: #fff;
$checkmark: rgb(93, 255, 93);
$error: rgb(255, 93, 107);

.loader {
  position: relative;
  width: 75px;
  height: 100px;
  
  &__bar {
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 50%;
    background: $bar-color;
    transform-origin: center bottom;
    box-shadow: 1px 1px 0 rgba(0,0,0,.2);
    
    @for $i from 1 through 5 { 
       &:nth-child(#{$i}) {
         left: ($i - 1) * 15px;
         transform: scale(1,$i*.2);
         animation: barUp#{$i} 4s infinite;
        }
    }
    
  }
  
  &__ball {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 10px;
    height: 10px;
    background: $ball-color;
    border-radius: 50%;
    animation: ball 4s infinite;
  }
}

@keyframes ball {
  0% {
    transform: translate(0, 0);
  }
  5% {
    transform: translate(8px, -14px);
  }
  10% {
    transform: translate(15px, -10px)
  }
  17% {
    transform: translate(23px, -24px)
  }
  20% {
    transform: translate(30px, -20px)
  }
  27% {
    transform: translate(38px, -34px)
  }
  30% {
    transform: translate(45px, -30px)
  }
  37% {
    transform: translate(53px, -44px)
  }
  40% {
    transform: translate(60px, -40px)
  }
  50% {
    transform: translate(60px, 0)
  }
  57% {
    transform: translate(53px, -14px)
  }
  60% {
    transform: translate(45px, -10px)
  }
  67% {
    transform: translate(37px, -24px)
  }
  70% {
    transform: translate(30px, -20px)
  }
  77% {
    transform: translate(22px, -34px)
  }
  80% {
    transform: translate(15px, -30px)
  }
  87% {
    transform: translate(7px, -44px)
  }
  90% {
    transform: translate(0, -40px)
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes barUp1 { 
  0% {
    transform: scale(1, .2);
  }
  40%{
    transform: scale(1, .2);
  }
  50% {
    transform: scale(1, 1);
  }
  90% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(1,.2);
  }
}
@keyframes barUp2 { 
  0% {
    transform: scale(1, .4);
  }
  40% {
    transform: scale(1, .4);
  }
  50% {
    transform: scale(1, .8);
  }
  90% {
    transform: scale(1, .8);
  }
  100% {
    transform: scale(1, .4);
  }
}
@keyframes barUp3 { 
  0% {
    transform: scale(1, .6);
  }
  100% {
    transform: scale(1, .6);
  }
}
@keyframes barUp4 { 
  0% {
    transform: scale(1, .8);
  }
  40% {
    transform: scale(1, .8);
  }
  50% {
    transform: scale(1, .4);
  }
  90% {
    transform: scale(1, .4);
  }
  100% {
    transform: scale(1, .8);
  }
}
@keyframes barUp5 { 
  0% {
    transform: scale(1, 1);
  }
  40% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, .2);
  }
  90% {
    transform: scale(1, .2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.success-checkmark {
    height: 115px;
    margin: 0 auto;
    text-align: center;
    
    .check-icon {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid $checkmark;
        
        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }
        
        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in;
        }
        
        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: none;
            transform: rotate(-45deg);
        }
        
        .icon-line {
            height: 5px;
            background-color: $checkmark;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            
            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
            }
            
            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
            }
        }
        
        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(58, 192, 63, 0.5);
        }
        
        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: none;
        }
    }
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

.error-x {
    height: 115px;
    margin: 0 auto;
    text-align: center;
    
    .error-icon {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid $error;
        
        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }
        
        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: none;
            transform: rotate(-45deg);
        }
        
        .error-line {
            height: 5px;
            background-color: $error;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            
            &.error-line-left {
                top: 37px;
                left: -2px;
                width: 84px;
                transform: rotate(55deg);
                animation: error-line-left 0.75s;
            }
            
            &.error-line-right {
              top: 37px;
              right: -2px;
              width: 84px;
              transform: rotate(123deg);
              animation: error-line-right 0.75s;
            }
        }
        
        .error-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(192, 58, 58, 0.5);
        }
    }
}

@keyframes error-line-left {
  0% {
    width: 0;
    left: 17px;
    top: 3px;
  }
  100% {
    top: 37px;
    left: -2px;
    width: 84px;
  }
}

@keyframes error-line-right {
  0% {
    top: 3px;
    right: 17px;
    width: 0;
  }
  100% {
    top: 37px;
    right: -2px;
    width: 84px;
  }
}