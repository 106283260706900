.custom-input {
  border: 1px solid black;
  width: 100%;
  resize: none;
}

.btn-primary {
  background: orange;
  border-color: orange;
  font-size: 18px;
}

.btn-primary:disabled {
  background: orange;
  border-color: orange;
}

.btn-primary:hover {
  background: rgb(233, 151, 0);
  border-color: rgb(233, 151, 0);
}